<template>
  <a-modal :maskClosable="false" v-model="visible" @cancel="handleCancel" :width="800" :title="isEdit ? '编辑' : '新建'" on-ok="handleOk">
    <template slot="footer">
      <div style="display:flex;justify-content: space-between;">
        <span>
          <a v-if='isTranslate' @click="cntinueToBuild">继续新建</a>
        </span>
        <span>
          <a-button key="back" @click="handleCancel" style="user-select:none">
            取消
          </a-button>
          <a-button key="submit" type="primary" style="user-select:none" @click="handleOk">
            完成
          </a-button>
        </span>
      </div>
    </template>
    <div style="display:flex;justify-content: space-between;">
      <div style="width:49%">
        <a-textarea
          v-if="!isEdit"
          @keydown="textareaChange"
          v-model="hint"
          :placeholder="`请输入提示字词汇\n支持从Excel复制粘贴，多条数据使用换行隔开`"
          :rows="20"
        />
        <a-input v-else v-model="hint" placeholder="请输入" />
      </div>
      <div style="width:49%">
        <a-textarea
          v-if="!isEdit"
          @keydown="textareaChange"
          v-model="revise"
          :placeholder="`请输入校正字词汇\n支持从Excel复制粘贴，多条数据使用换行隔开`"
          :rows="20"
        />
        <a-input v-else v-model="revise" placeholder="请输入" />
      </div>
    </div>
  </a-modal>
</template>

<script>
import { getAction, postAction } from '../../../api/manage'
import { USER_INFO } from '@/store/mutation-types'
import Vue from 'vue'
export default {
  props: {
    parent: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      visible: false,
      isEdit: false,
      userInfo: null,
      hint: '',
      revise: '',
      recordId: null,
      isTranslate:false
    }
  },
  created() {},
  methods: {
    textareaChange(e) {
      if ((e.key == 'V' || e.key == 'v') && e.metaKey) {
        setTimeout(() => {
          if (this.hint[this.hint.length - 1] == '\n') {
            this.hint = this.hint.slice(0, -1)
          }
          if (this.revise[this.revise.length - 1] == '\n') {
            this.revise = this.revise.slice(0, -1)
          }
        }, 0)
      }
      if ((e.key == 'V' || e.key == 'v') && e.ctrlKey) {
        setTimeout(() => {
          if (this.hint[this.hint.length - 1] == '\n') {
            this.hint = this.hint.slice(0, -1)
          }
          if (this.revise[this.revise.length - 1] == '\n') {
            this.revise = this.revise.slice(0, -1)
          }
        }, 0)
      }
    },
    openModal() {
      this.isTranslate=true
      this.isEdit = false
      this.visible = true
      this.userInfo = Vue.ls.get(USER_INFO)
    },
    async cntinueToBuild() {
      if (!this.hint && !this.revise) return this.$message.error('请输入提示或校正')
      let hints = this.hint.split('\n')
      let revises = this.revise.split('\n')
      let params = []
      if (hints.length > revises.length) {
        hints.forEach((i, idx) => {
          params.push({
            productionId: this.parent.productionId,
            username: this.userInfo.nickName,
            userId: this.userInfo.id,
            hint: i,
            revise: revises[idx] || '',
            avatar: this.userInfo.avatar
          })
        })
      } else {
        revises.forEach((i, idx) => {
          params.push({
            productionId: this.parent.productionId,
            username: this.userInfo.nickName,
            userId: this.userInfo.id,
            revise: i,
            hint: hints[idx] || '',
            avatar: this.userInfo.avatar
          })
        })
      }
      const res = await postAction('/term/table/notes/edit', params)
      if (res.code == 200) {
        this.$message.success('添加成功')
        this.hint = ''
        this.revise = ''
        this.recordId = null
      } else {
        this.$message.error(res.msg)
      }
    },
    open(record) {
      if (record) {
        this.isEdit = true
        this.hint = record.hint
        this.revise = record.revise
        this.recordId = record.id
      } else {
        this.isEdit = false
      }
      this.visible = true
      this.userInfo = Vue.ls.get(USER_INFO)
    },
    async handleOk() {
      if (!this.hint && !this.revise) return this.$message.error('请输入提示或校正')
      if (!this.hint.replaceAll(' ','').replaceAll('\n','').length || !this.revise.replaceAll(' ','').replaceAll('\n','').length) return this.$message.error('不能输入空白内容')
      if (!this.isEdit) {
        let hints = this.hint.split('\n')
        let revises = this.revise.split('\n')
        let params = []
        if (hints.length > revises.length) {
          hints.forEach((i, idx) => {
            params.push({
              productionId: this.parent.productionId,
              username: this.userInfo.nickName,
              userId: this.userInfo.id,
              hint: i,
              revise: revises[idx] || '',
              avatar: this.userInfo.avatar
            })
          })
        } else {
          revises.forEach((i, idx) => {
            params.push({
              productionId: this.parent.productionId,
              username: this.userInfo.nickName,
              userId: this.userInfo.id,
              revise: i,
              hint: hints[idx] || '',
              avatar: this.userInfo.avatar
            })
          })
        }
        const res = await postAction('/term/table/notes/edit', params)
        if (res.code == 200) {
          this.$message.success('添加成功')
          this.innitForm()
        } else {
          this.$message.error(res.msg)
        }
      } else {
        let params = {
          productionId: this.parent.productionId,
          username: this.userInfo.nickName,
          userId: this.userInfo.id,
          revise: this.revise,
          id: this.recordId,
          hint: this.hint,
          avatar: this.userInfo.avatar
        }
        const res = await postAction('/term/table/notes/edit', [params])
        if (res.code == 200) {
          this.$message.success('添加成功')
          this.innitForm()
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    handleCancel() {
      this.innitForm()
    },
    innitForm() {
      this.hint = ''
      this.revise = ''
      this.visible = false
      this.recordId = null
      this.$emit('loadData')
    }
  }
}
</script>

<style scoped lang="less"></style>
